.images-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding-bottom: 100px;
    margin-top: -20px;
    width: 100%;

}

.image-box {
    position: relative;
    flex: 1 1 20%;
    height: 250px;
    max-width: 400px;
    margin-left: 60%;
    overflow: hidden;
    border-radius: 10px;
    

    .portfolio-image {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .content {
        position: absolute;
        width: 100%;
        z-index: 3;
        bottom: -70px;
        padding: 10px 20px;
        transition: cubic-bezier(0.645, 0.045, 0.355, 1);
        opacity: 0;


    }

    .title {
        margin-top: 0;
        margin-bottom: 0;
        color: white;
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;

    }

    .description {
        margin-bottom: 5px;
        font-size: 14px;
        color: white;
        font-weight: 700;
        margin-right: 25px;
    }

    .btn {
        margin-top: 30px;
        margin-bottom: 10px;
        padding: 0 23px;
        height: 40px;
        line-height: 34px;
        border: 2px solid #ffd700;
        border-radius: 4px;
        font-size: 14px;
        color: white;
        background: transparent;
        text-transform: uppercase;
        font-weight: 700;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;

    }

    .btn:hover {
        transform: translateY(-3px);
        background: #ffd700;

    }

    &:after {
        content: "";
        background: black;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        transition: all cubic-bezier(0.645, 0.045, 0.355, 1);
        opacity: 0;
    }

    &:hover:after {
        opacity: 0.85;
    }

    &:hover .content {
        bottom: 0;
        transition-duration: 1s;
        opacity: 1;
    }

}

@media screen and (max-width: 1200px) {
    .image-box {
        position: relative;
        flex: 1 1 100%; 
        max-width: 300px;
        height: 300px;
        
        margin: 0 auto; 
        // overflow-y: auto;
        border-radius: 10px;
        margin-bottom: 10px;
  
      
     }
    }