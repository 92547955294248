.home-page {
    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 40%;
      max-height: 90%;
    }
  
    h1 {
      color: #fff;
      font-size: 56px;
      line-height: 53px;
      letter-spacing: 2px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;


  
      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -40px;
        left: -13px;
        opacity: 0.6;
      }

      &:after {
        content: '</h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -5px;
        left: -13px;
        opacity: 0.6;
        display: block;
      }

      img {
        width: 62px;
        margin-right: -8px;
        margin-bottom: -10px;
        opacity: 0;
        height: auto;
        animation: rotateIn 1s linear both;
        animation-delay: 1.4s;
      }
    }
  
    h2 {
      color: #8d8d8d;
      margin-top: 55px;
      margin-left: 40px;
      font-weight: 400;
      font-size: 11px;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeIn 1s 4.2s backwards;
      

      &:before {
        content: '<h2>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -7px;
        left: -13px;
        opacity: 0.6;
        display: inline-flex;
      }

      &:after {
        content: '</h2>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -7px;
        left: 453px;
        opacity: 0.6;
        display: inline-flex;
      }


    }
  
    .contact-me-button {
      color: #ffd700;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #ffd700;
      margin-top: 25px;
      float: left;
      animation: fadeIn 1.5s 3.2s backwards;
      white-space: nowrap;
  
      &:hover {
        background: #ffd700;
        color: #333;
    }
}
}
.picture-container {
  width: 400px;
  height: 409px;
  position: absolute;
  top: 0;
  right: 7%;
  bottom: 0;
  left: auto;
  margin: auto;
  animation: fadeIn 2s 2.7s backwards;

  .picture {
    width: 435px;
    margin-top: -5%;
    margin-left: -25%;
    border-radius: 9999px;
    box-shadow: 15px 40px 60px 25px black;
    opacity: 1;
    //animation: ;

  }

  
}




@media screen and (max-width: 1200px) {
  .tags, .home-page h1:before, .home-page h1:after, .home-page h2:before, .home-page h2:after {
    display: none;
  }

  .home-page {
    .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
      font-size: 8px;
      margin-top: 10%;
    }

    .contact-me-button {
      float: none; 
      display: block;
      margin-left: 10%;
      width: 125px;
  
    }
  }


  
  .picture-container {
    width: 400px;
    height: 409px;
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    margin: auto;
    animation: fadeIn 2s 2.7s backwards;

    .picture {
      width: 435px;
      margin-top: -5%;
      margin-left: 15%;
      border-radius: 9999px;
      box-shadow: 15px 40px 60px 25px black;
      opacity: 1;
      //animation: ;

    }

  }

  
}

@media (min-width: 428px) and (max-width: 900px) {
  .tags, .home-page h1:before, .home-page h1:after, .home-page h2:before, .home-page h2:after {
    display: none;
  }

  .home-page {
    .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
      font-size: 8px;
      margin-top: 0%;
      text-align: center;
    }

    h1 {
      color: #fff;
      font-size: 53px;
      line-height: 53px;
      letter-spacing: 2px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;
    }
    .contact-me-button {
      width: 125px;
      margin: auto;
      margin-top: 50px;
      position: relative;
    }
        
  }


  
  .picture-container {
    width: 400px;
    height: 409px;
    position: relative;
    top: 50px;
    right: 20px;
    bottom: 0;
    left: auto;
    margin: auto;
    animation: fadeIn 2s 2.7s backwards;
    justify-content: center;

    .picture {
      width: 335px;
      // margin-top: 50%;
      // margin-left: 15%;
      border-radius: 9999px;
      box-shadow: 15px 40px 60px 25px black;
      opacity: 1;
      

    }

  }

  
}

@media (min-width: 300px) and (max-width: 428px) {
  .tags, .home-page h1:before, .home-page h1:after, .home-page h2:before, .home-page h2:after {
    display: none;
  }

  .home-page {
    .text-zone {
      position: initial;
      width: 100%;
      transform: none;
      padding: 10px;
      box-sizing: border-box;
      font-size: 4px;
      font-weight: 200px;
      margin-top: 0%;
      text-align: center;

      h1 {
        color: #fff;
        font-size: calc(42px + 0.390625vw);
        line-height: 48px;
        letter-spacing: 2px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;
    }

    h2 {
      color: #8d8d8d;
      margin-top: 55px;
      margin-left: 5px;
      font-weight: 400;
      font-size: calc(9px + 0.390625vw);
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeIn 1s 4.2s backwards;
  }}
      
    .contact-me-button {
      width: 125px;
      margin: auto;
      margin-top: 50px;
      position: relative;
    }
        
  }


  
  .picture-container {
    width: 400px;
    height: 409px;
    position: relative;
    top: 50px;
    right: 20px;
    bottom: 0;
    // left: auto;
    margin: auto;
    animation: fadeIn 2s 2.7s backwards;
    justify-content: center;

    .picture {
      position: relative;
      width: 300px;
      // margin-top: 50%;
      // margin-left: 15%;
      border-radius: 9999px;
      box-shadow: 15px 40px 60px 25px black;
      opacity: 1;
      

    }

  }

  
}