.aboutmecontainer {
    background-color: transparent;
    width: 35%;
    margin-left: 55%;
    margin-top: 100px;
    height: 500px;
    border-radius: 25px;
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
  
    .chevleft,
    .chevright {
      display: none;
    }
  
    &:hover {
      .chevleft,
      .chevright {
        display: block;
      }
    }
  
    .hovered & {
      .chevleft,
      .chevright {
        display: block;
      }
    }
  
    div {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: white;
      font-size: 2rem;
      cursor: pointer;
    }
  
    .chevleft {
      margin-left: 2px;
      padding: 10px;
      background: black;
      opacity: 0.4;
      border-radius: 9999px;
  
      &:hover {
        opacity: 1;
      }
    }
  
    .chevright {
      margin-left: 93%;
      padding: 10px;
      background: black;
      opacity: 0.4;
      border-radius: 9999px;
      
  
      &:hover {
        opacity: 1;
      }
    }
  
    .about-me-pictures {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: background-image 0.1s ease-in-out;
      pointer-events: none; 
    }
  }
  
@media screen and (max-width: 1200px) {
  .aboutmecontainer  {
    position: relative;
    width: 400px;
    height: 400px;
    margin: auto;
    justify-content: center;
    display: block;
    margin-bottom: 30px;

    .chevright {
      margin-left: 365px;
      padding: 10px;
      background: black;
      opacity: 0.4;
      border-radius: 9999px;
  
      &:hover {
        opacity: 1;
      }}
    
   }
}


@media (min-width: 700px) and (max-width: 900px) {
  .aboutmecontainer  {
    position: relative;
    width: 350px;
    height: 350px;
    margin: auto;
    justify-content: center;
    display: block;
    margin-bottom: 30px;

    .chevright {
      margin-left: 315px;
      padding: 10px;
      background: black;
      opacity: 0.4;
      border-radius: 9999px;
  
      &:hover {
        opacity: 1;
      }}
    
   }
  }

@media (min-width: 300px) and (max-width: 700px) {
  .aboutmecontainer  {
    position: relative;
    width: 300px;
    height: 300px;
    margin: auto;
    justify-content: center;
    display: block;
    
    .chevright {
      margin-left: 265px;
      padding: 10px;
      background: black;
      opacity: 0.4;
      border-radius: 9999px;
  
      &:hover {
        opacity: 1;
      }}
   }



  //  .about-me-pictures  {
  //   position: relative;
  //   width: 300px;
  //   height: 300px;
  //   margin-left: auto auto auto auto;
  //   justify-content: center;
  // }

}