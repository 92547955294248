.standout-link {
    color: #ffd700;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: aqua;
    }
}


.skills-display {
    width: 45%;
    margin-left: 50%;
    margin-top: 175px;
  }
  
  .skills-list {
    display: flex;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .skills-item {
    color: white;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(33.33% - 20px);
    animation: fadeIn 1s 1s;  

    :hover {
        color: #ffd700;
    }
  }
  
  .skill-name {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 40px; 
    
  }
  
  @media screen and (max-width: 1200px) {
    .skills-display  {
      position: relative;
      width: 300px;
      height: 300px;
      // justify-content: center;
      // align-items: center;
      // display: flex;
      // object-fit: cover;
      // margin-left: 50rem;
      // margin-top: 50px;
      margin: auto auto auto auto;
      
      
  
      
     }}

     @media (min-width: 300px) and (max-width: 900px) {
      .skills-display  {
        position: relative;
        width: 300px;
        height: 300px;
        margin: auto auto auto auto;
        
        
    
        
       }
      }