.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }
            

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'], input[type='email'] {
        width: 100%;
        border: 0;
        background: #282E44;
        height: 50px;
        font-size: 16px;
        color: white;
        padding: 0 20px;
        box-sizing: border-box;
    }

    textarea {
        width: 100%;
        border: 0;
        background: #282E44;
        height: 50px;
        font-size: 16px;
        color: white;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box
    }

    .contact-me-button {
        color: #ffd700;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: 1px solid #ffd700;
        float: right;
        border-radius: 4px;

        &:hover {
            background: #ffd700;
            color: #333;
            cursor: pointer;

        }
    }
}
.map-wrap {
    background: #282E44;
    float: right;
    width: 53%;
    height: 115%;
    margin-top: -3%;
  }
  
  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
  }
  
  .info-map {
    position: absolute;
    background: #000;
    top: 50px;
    right: 30%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

  }
  @media screen and (max-width: 1200px) {
    .map-wrap {
      float: none;
      margin: 0;
      width: 100%;
      height: 400px;
    }
    
    .info-map {
      display: none;
    }
  }


